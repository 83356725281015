

%btn {
  display: flex;
  align-items: center;
  padding: 1rem 1.4rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition-slow);
}
%btn svg {
  stroke-width: 0.5;
  margin-left: 0.6rem;
}

.btn-contained {
  @extend %btn;
  background-color: var(--button-main);
  border: unset;
  color: white;
}
.btn-contained:hover {
  background-color: var(--button-dark);
}
.btn-outlined {
  @extend %btn;
  border: 2px solid;
  border-color:var(--button-light);
}
.btn-outlined:hover {
  @extend %btn;
  border-color:var(--button-dark);
}
.btn-text {
  @extend %btn;
  border: unset;
}
.btn-icon{
  @extend %btn;
  @extend .btn-text;
  aspect-ratio: 1/1;
  border-radius: 40px;
}
.btn-text,
.btn-outlined {
  background-color: unset;
  color: var(--button-dark);
}

.btn-squared {
  border-radius: 5px;
}
.btn-rounded {
  border-radius: 30px;
}

%btn:active{
  background-color: var(--button-active);
}


.btn-group{
  display: flex;
}

.first-group{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.middle-group{
  border-radius: 0;
  border-left: unset;
}
.last-group{
  border-left: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media only screen and (max-width: 768px) {
  %btn{
    margin-left: auto;
    margin-right: auto;
  }
}

