@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--primary-50);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  --primary-50: #f8f5ee;
  --primary-100: #f1ecde;
  --primary-150: #eae2cd;
  --primary-200: #e3d8bc;
  --primary-250: #dbceac;
  --primary-300: #d4c59b;
  --primary-350: #cdbb8a;
  --primary-400: #c6b179;
  --primary-450: #bfa869;
  --primary-500: #B89E58;
  --primary-550: #ac9149;
  --primary-600: #998141;
  --primary-650: #867139;
  --primary-700: #736131;
  --primary-750: #5f5129;
  --primary-800: #4c4020;
  --primary-850: #393018;
  --primary-900: #262010;
  --primary-950: #131008;

  --gray-100: #e6e6e6;
  --gray-200: #cccccc;
  --gray-300: #b3b3b3;
  --gray-400: #999999;
  --gray-500: #808080;
  --gray-600: #666666;
  --gray-700: #4d4d4d;
  --gray-800: #333333;
  --gray-900: #1a1a1a;

  --white: #ffffff;
  --black: #000000;
  
  --error: #d32f2f;

  --button-main: var(--primary-500);
  --button-light: var(--primary-450);
  --button-dark: var(--primary-550);

  --button-active: var(--primary-350);


  --primary-bg: rgba(158, 134, 67, 0.14);
  --primary-active: #CEC1A0;

  --primary-light: #C0AF85;
  --primary: #9e8543;
  --primary-hover: rgb(148, 123, 57);

  --gray-line: rgb(192, 192, 192);
  --gray--main: rgb(50, 50, 50);
  --gray--light: rgb(90, 90, 90);

  --zIndex-1: 1;
  --zIndex-2: 1;
  --zIndex-3: 1;
  --zIndex-4: 1;
  --zIndex-5: 1;
  --zIndex-6: 1;
  --zIndex-7: 1;
  --zIndex-8: 1;
  --zIndex-9: 1;
  --zIndex-10: 10;

  --zIndex-7--navbar: var(--zIndex-7);

  --transition-slow: ease 0.3s all;

  background-color: var(--primary-50);

}
h1, h2, h3, h4, h5, h6, p, a, button{
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1{
  font-size: 46px;
  color: white;
}
h2{
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 26px;
  color: var(--gray--main);
}
p{
  font-size: 16px;
  color: var(--gray--light);
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  font-weight: 300;
}
strong{
  font-weight: 400;
  color: var(--gray-900);
}

.w-56{
  width: 56%;
}

.p-lead{
  font-size: 20px;
  color: var(--gray-900);
}
.p-strong{
  color: var(--gray--main);
  font-weight:400;
}
.p-strong p{
  color: var(--gray--main);
  font-weight:400;
}
.p-center{
  text-align: center;
}
.p-justify{
  text-align: justify;
}
.p-white{
  color: white;
}
.p-white p{
  color: white;
}
.p-quote{
  font-size: 14px;
  font-style: italic;
  margin-bottom: 20px;
}
.container{
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
}
.container-sm{
  max-width: 1000px;
  width: 56%;
  margin: 0 auto;
}
.section{
  padding-top: 105px;
}
.flex{
  display: flex;
  gap: 30px;
}
.flex-big{
  display: flex;
  gap: 40px;
}
.flex-column{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mb-30{
  margin-bottom: 30px;
}

.mb-10{
  margin-bottom: 10px;
}

.flex-end{
  margin-top: auto;
}

.linkButton{
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.linkButton span{
  margin-left: 10px;
}

.error{
  color: var(--error);
  font-size: 0.85rem;
  margin-top: 5px;
  font-weight: 400;
  align-self: start;
}

@media only screen and (max-width: 768px) {
  h1, h2, p{
    text-align: center;
  }
  h1{
    font-size: 32px;
  }
  h2{
    font-size: 24px;
  }
  .container-sm{
    width: 90%;
    margin: 0 auto;
  }
  .flex{
    flex-direction: column;
    gap: 45px;
  }
  .section{
    padding-top: 60px;
  }
  .mb-30{
    margin-bottom: 15px;
  }
  .flex-column{
    gap: 40px;
  }
  .linkButton{
    justify-content: center;
  }
}
